import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { array, string, bool, oneOfType } from 'prop-types';

const VideoPlayer = ({ sources, autoPlay, ...props }) => {
  return (
    <ReactPlayer
      width={540}
      url={sources}
      playing={autoPlay}
      muted={autoPlay}
      controls
      {...props}
    />
  );
};

VideoPlayer.defaultProps = {
  autoPlay: true,
};

VideoPlayer.propTypes = {
  sources: oneOfType([array, string]).isRequired,
  autoPlay: bool,
};

export default VideoPlayer;
