import client from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

// eslint-disable-next-line no-undef
const projectId = process.env.REACT_APP_SANITY_PROJECT;
const dataset = 'production';
export const sanityClient = client({
  projectId,
  dataset,
  useCdn: true,
});

const builder = imageUrlBuilder(sanityClient);

export function urlFor(source) {
  return builder.image(source);
}

export async function getConfigForSlug(path) {
  if (!path || path.length <= 0) return false;
  const query = `*[_type == "quiz" && slug.current == "${path}"] { ... }[0]`;
  const quiz = await sanityClient.fetch(query);
  if (!quiz) return;
  return quiz;
}
