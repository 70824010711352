import React, { Component } from 'react';
import update from 'react-addons-update';
import _ from 'lodash';
import { any } from 'prop-types';

import loading from './loading.gif';

import { dangerouslyGetMarkup } from './lib/utils';
import styled from 'styled-components';

const SubmissionFormStyle = styled.div`
  form {
    /* padding: 10px; */
    display: grid;
    gap: 1rem;
    min-width: 400px;
    * {
      /* border: 1px solid red; */
    }
    > div {
      /* border: 3px solid green; */
      padding: 5px;
      display: grid;
      /* grid-template-columns: 1fr 2fr; */
      /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
      gap: 5px;
      /* grid-auto-rows: auto auto; */

      input {
        /* border: 3px solid red; */
        background: #eee;
        padding: 10px 15px;
        font-size: 16px;
        border: none;
        border-radius: 5px;
      }
    }

    .feedback-field-error {
      display: none;
    }
  }
`;

class SubmissionForm extends Component {
  constructor(props) {
    super(props);
    this.tracker = props.tracker;
    const state = {
      errors: {},
      isSending: false,
      showServerError: false,
      hasPages: false,
      pageCount: 1,
      currentPage: 1,
    };

    if (this.props.quiz.form) {
      this.props.quiz.form.fields.forEach(field => {
        if (field.type === 'page') {
          state.hasPages = true;
          state.pageCount += 1;
        }
        if (this.props.answers && field.cssClass && field.cssClass.indexOf('form-answers') !== -1) {
          const correct = this.props.answers.filter(answer => {
            return answer.answer.is_correct;
          });
          const answers = this.getAnswers();
          if (answers.length > 0) {
            state[field.id] = correct.length + '/' + this.props.answers.length + ' | ' + answers;
          }
        }

        // Add Type Result to the `form-type-result` CSS class field
        if (this.props.feedback && field.cssClass && field.cssClass.indexOf('form-type-result') !== -1) {
          if (this.props.feedback.type_feedback_name) {
            state[field.id] = this.props.feedback.type_feedback_name;
          }
        }

        // Add values to the form fields.
        if (field.defaultValue === '{embed_post:ID}') {
          if (this.props.quiz.page_id) {
            state[field.id] = this.props.quiz.page_id;
          } else {
            state[field.id] = this.props.quiz.quiz_id;
          }
        }
        // Check if the form needs to be populated dynamically
        if (field.allowsPrepopulate && field.inputName) {
          state[field.id] = this.getQueryParam(field.inputName);
        }
        // Default
        if (!state[field.id]) {
          state[field.id] = field.defaultValue;
        }
      });
    }
    this.state = state;
  }

  getQueryParam(param) {
    var qd = {};
    if (window.location.search)
      window.location.search
        .substr(1)
        .split('&')
        .forEach(function (item) {
          var s = item.split('='),
            k = s[0],
            v = s[1] && decodeURIComponent(s[1]); //  null-coalescing / short-circuit
          //(k in qd) ? qd[k].push(v) : qd[k] = [v]
          (qd[k] = qd[k] || []).push(v); // null-coalescing / short-circuit
        });

    // assume that we just accept the 'first' one.
    if (qd[param] && qd[param][0]) {
      return qd[param][0];
    }
    return '';
  }

  trackEvent() {
    const category = `Engage - ${document.title}`;
    let action = 'form submitted';
    let label = 'form submitted';
    this.tracker.sendPageView({ pageValues: { name: 'thank_you', level: 4 } });
    if (window.s) {
      window.s.clearVars();
      window.s.pageName = category + ':' + label;
      void window.s.t();
    }
    if (window.ga) {
      window.ga('send', 'event', category, label, action);
    }
    if (window.fbq) {
      window.fbq('track', 'ViewContent', { value: label });
    }
    if (window.utag) {
      window.utag.view({
        page_name: category + ':' + label,
        page_hierarchy: category + ':' + label,
      });
    }
  }

  submitForm = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.state.isSending) {
      return;
    }

    let submitObject = {
      input_values: {},
    };

    this.setState({
      errors: {},
      showServerError: false,
      isSending: true,
    });

    for (var key in this.state) {
      // eslint-disable-next-line
      if (this.state.hasOwnProperty(key) && key.indexOf('errors') === -1) {
        submitObject.input_values['input_' + key] = this.state[key];
      }
    }

    if (this.state.pageCount > 1) {
      submitObject.source_page = this.state.currentPage;
      submitObject.target_page = this.state.currentPage + 1;
      if (this.state.pageCount === this.state.currentPage) {
        submitObject.target_page = 0;
      }
    }

    if (this.props.quiz.submission_url) {
      fetch(this.props.quiz.submission_url, {
        method: 'POST',
        body: JSON.stringify(submitObject),
      })
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            this.trackEvent();
            return response;
          }
          var error = new Error(response.statusText);
          error.response = response;
          throw error;
        })
        .then(res => {
          return res.json();
        })
        .then(resJson => {
          this.setState({
            isSending: false,
          });
          if (resJson.status === 200 && resJson.response.is_valid) {
            if (this.state.pageCount > 1) {
              if (this.state.pageCount === this.state.currentPage) {
                this.props.changeState('nextPage');
              } else {
                this.setState({
                  errors: {},
                  isSending: false,
                  currentPage: this.state.currentPage + 1,
                });
              }
            } else {
              this.props.changeState('nextPage');
            }
          }

          if (!resJson.response.is_valid && resJson.response.validation_messages) {
            this.setState({
              errors: resJson.response.validation_messages,
              isSending: false,
            });
          }
        })
        .catch(error => {
          console.error('Submitting the form failed: ', error);
          this.setState({
            showServerError: true,
            isSending: false,
          });
          window.setTimeout(() => {
            this.submitForm();
          }, _.random(1, 8) * 1000);
        });
    }
  };

  handleChange = (field, choice, event) => {
    let newState;
    let key = field.id;
    if (field.type === 'checkbox') {
      let checkbox = _.find(field.inputs, { label: choice.text });
      if (checkbox) {
        key = checkbox.id.replace('.', '_');
        let newVal = this.state[key] === choice.text ? '' : choice.text;
        newState = update(this.state, { [key]: { $set: newVal } });
      }
    } else {
      if (choice) {
        newState = update(this.state, { [key]: { $set: choice.text } });
      } else if (event.target) {
        newState = update(this.state, { [key]: { $set: event.target.value } });
      }
    }

    if (newState) {
      this.setState(newState);
    }
  };

  getAnswers = () => {
    let answers = _.map(this.props.answers, (a, index) => {
      if (a.answer && a.answer.answer && a.answer.answer[0].acf_fc_layout === 'text') {
        // Remove all HTML from the answer text
        let div = document.createElement('div');
        div.innerHTML = _.trim(a.answer.answer[0].text);
        return 'Q: ' + (index + 1) + ' A:' + _.escape(_.trim(div.textContent || div.innerText || ''));
      }
      return '';
    });
    return answers.join(' | ');
  };

  render() {
    if (this.props.status.currentPage !== 'feedback') {
      return null;
    }

    if (!this.props.quiz.show_submission_form || !this.props.quiz.form) {
      return null;
    }

    let currentPage = 1;
    let nextButtonText = this.props.quiz.form.button.text;

    let formFields = this.props.quiz.form.fields.map((field, i) => {
      if (this.state.hasPages) {
        if (field.type === 'page') {
          if (currentPage === this.state.currentPage) {
            if (field.nextButton && field.nextButton.text) {
              nextButtonText = field.nextButton.text;
            }
          }
          currentPage += 1;
          return null;
        }

        if (currentPage !== this.state.currentPage) {
          return null;
        }
      }

      if (field.type === 'text') {
        return (
          <div className={field.cssClass + ' feedback-field text'} key={field.id}>
            <label htmlFor={field.id} className="feedback-field-label">
              {field.label}
            </label>
            <input
              type="text"
              placeholder={field.placeholder}
              id={field.id}
              defaultValue={this.state[field.id]}
              onChange={event => {
                this.handleChange(field, null, event);
              }}
            />
            <div className="feedback-field-error">{this.state.errors[field.id]}</div>
          </div>
        );
      }

      if (field.type === 'email') {
        return (
          <div className={field.cssClass + ' feedback-field email'} key={field.id}>
            <label htmlFor={field.id} className="feedback-field-label">
              {field.label}
            </label>
            <input
              type="email"
              placeholder={field.placeholder}
              id={field.id}
              defaultValue={this.state[field.id]}
              onChange={event => {
                this.handleChange(field, null, event);
              }}
            />
            <div className="feedback-field-error">{this.state.errors[field.id]}</div>
          </div>
        );
      }

      if (field.type === 'number') {
        return (
          <div className={field.cssClass + ' feedback-field number'} key={field.id}>
            <label htmlFor={field.id} className="feedback-field-label">
              {field.label}
            </label>
            <input
              type="text"
              placeholder={field.placeholder}
              name={'textarea' + field.id}
              id={field.id}
              defaultValue={this.state[field.id]}
              onChange={event => {
                this.handleChange(field, null, event);
              }}
            />
            <div className="feedback-field-error">{this.state.errors[field.id]}</div>
          </div>
        );
      }

      if (field.type === 'textarea') {
        return (
          <div className={field.cssClass + ' feedback-field number'} key={field.id}>
            <label htmlFor={field.id} className="feedback-field-label">
              {field.label}
            </label>
            <textarea
              placeholder={field.placeholder}
              name={'textarea' + field.id}
              id={field.id}
              defaultValue={this.state[field.id]}
              onChange={event => {
                this.handleChange(field, null, event);
              }}
            />
            <div className="feedback-field-error">{this.state.errors[field.id]}</div>
          </div>
        );
      }

      if (field.type === 'html') {
        return (
          <div className={field.cssClass + ' feedback-field html'} key={field.id}>
            <div dangerouslySetInnerHTML={dangerouslyGetMarkup(field.content)}></div>
          </div>
        );
      }

      if (field.type === 'select') {
        let selectItems = field.choices.map((choice, c) => {
          return (
            <option defaultValue={choice.value} key={'choice-' + i + '-' + c}>
              {choice.text}
            </option>
          );
        });
        return (
          <div className={field.cssClass + ' feedback-field select'} key={field.id}>
            <label className="feedback-field-label">{field.label}</label>
            <select>{selectItems}</select>
            <div className="feedback-field-error">{this.state.errors[field.id]}</div>
          </div>
        );
      }

      if (field.type === 'radio') {
        let radioItems = field.choices.map((choice, index) => {
          return (
            <label
              key={'radio-' + field.id + '-' + index}
              onClick={event => {
                this.handleChange(field, choice, event);
              }}
            >
              <input type="radio" defaultValue={choice.text} checked={this.state[field.id] === choice.text} />
              <span>{choice.text}</span>
            </label>
          );
        });
        return (
          <div className={field.cssClass + ' feedback-field radio'} key={field.id}>
            <label className="feedback-field-label">{field.label}</label>
            <div className="radio-items">{radioItems}</div>
          </div>
        );
      }

      if (field.type === 'checkbox') {
        let checkboxItems = field.choices.map((choice, index) => {
          return (
            <label key={'checkbox-' + field.id + '-' + index}>
              <input
                type="checkbox"
                checked={this.state[field.inputs[index].id.replace('.', '_')] === choice.text}
                onChange={event => {
                  this.handleChange(field, choice, event);
                }}
                defaultValue={choice.text}
              />
              <span>{choice.text}</span>
            </label>
          );
        });
        return (
          <div className={field.cssClass + ' feedback-field checkbox'} key={field.id}>
            <label className="feedback-field-label">{field.label}</label>
            <div className="checkbox-items">{checkboxItems}</div>
          </div>
        );
      }

      if (field.type === 'hidden') {
        return (
          <div className={field.cssClass + ' feedback-field hidden'} key={field.id}>
            <input type="hidden" placeholder={field.placeholder} id={field.id} defaultValue={this.state[field.id]} />
          </div>
        );
      }

      return (
        <div className="feedback-field unsupported" key={'Unsupported-' + i}>
          <span>Unsupported field type used.</span>
        </div>
      );
    });

    formFields = _.compact(formFields);

    return (
      <SubmissionFormStyle className="et-submission-form">
        <form>
          {formFields}
          <div className="et-submission-form-button">
            <button type="button" onClick={this.submitForm}>
              {nextButtonText}
            </button>
            {this.state.isSending ? <img style={{ paddingLeft: '20px' }} src={loading} alt="Saving..." /> : null}
            {this.state.showServerError ? (
              <div className="et-submission-form-server-error">
                <span>Error submitting the form. Retrying automatically…</span>
              </div>
            ) : null}
          </div>
        </form>
      </SubmissionFormStyle>
    );
  }
}

export default SubmissionForm;

//TODO: Types
SubmissionForm.defaultProps = {
  feedback: undefined,
};

SubmissionForm.propTypes = {
  tracker: any.isRequired,
  quiz: any.isRequired,
  answers: any.isRequired,
  status: any.isRequired,
  changeState: any.isRequired,
  feedback: any,
};
