import talkmoreStandard from './../../assets/talkmore/talkmore_logo.svg';
import hurtigrutenLogo from './../../assets/hurtigruten/logo.png';
import evokedSetLogo from './../../assets/evokedset/logo.svg';
import alternative from './../../assets/talkmore/talkmore_logo_strom.png';

// My idea is that this document knows what theme and
// theme variant is set and serves variables accordingly
// Does this need to be a component in order to access state?

// console.log(logo);
// export const themeStyles = {
//   hello: 'something',
// };

export const clientVars = {
  hurtigruten: {
    baseColour: 'yellow',
    logoImage: hurtigrutenLogo,
    fonts: `@font-face {
                font-family: CircularProBook;
                src: url(https://talkmore.no/Content/fonts/CircularProBook.eot);
                src: url(https://talkmore.no/Content/fonts/CircularProBook.eot) format("embedded-opentype"), url(https://talkmore.no/Content/fonts/CircularProBook.woff2) format("woff2"), url(https://talkmore.no/Content/fonts/CircularProBook.woff) format("woff"), url(https://talkmore.no/Content/fonts/CircularProBook.ttf) format("truetype"), url(https://talkmore.no/Content/fonts/CircularProBook.svg#CircularProBook) format("svg");
                font-weight: 400;
                font-style: normal;
                font-display: swap;
              }
              @font-face {
                font-family: CircularProBold;
                src: url(https://talkmore.no/Content/fonts/CircularProBold.eot);
                src: url(https://talkmore.no/Content/fonts/CircularProBold.eot) format("embedded-opentype"), url(https://talkmore.no/Content/fonts/CircularProBold.woff2) format("woff2"), url(https://talkmore.no/Content/fonts/CircularProBold.woff) format("woff"), url(https://talkmore.no/Content/fonts/CircularProBold.ttf) format("truetype"), url(https://talkmore.no/Content/fonts/CircularProBold.svg#CircularProBold) format("svg");
                font-weight: 400;
                font-style: normal;
                font-display: swap;
              }`,
    button: `
    min-height: 54px;
      word-break: break-word;
      font-size: 19.5px;
      line-height: 1.75;
      color: black;
      -webkit-font-smoothing: antialiased;

      font-family: CircularProBold, Helvetica, Arial, sans-serif;
      font-family: sans-serif;
      position: relative;
      border: none;
      background: linear-gradient(326.89deg, #57dd1c 34.17%, #82ee40 76.61%),
        #78d54a;

      border-radius: 16px;
      padding: 16px 24px;
      line-height: 145%!important;
      font-size: 22px!important;

      cursor: pointer;
      
    
      `,
  },
  talkMore: {
    baseColour: `linear-gradient(326.89deg, #57dd1c 34.17%, #82ee40 76.61%), #78d54a;`,
    logoImage: talkmoreStandard,
    fonts: `@font-face {
                font-family: CircularProBook;
                src: url(https://talkmore.no/Content/fonts/CircularProBook.eot);
                src: url(https://talkmore.no/Content/fonts/CircularProBook.eot) format("embedded-opentype"), url(https://talkmore.no/Content/fonts/CircularProBook.woff2) format("woff2"), url(https://talkmore.no/Content/fonts/CircularProBook.woff) format("woff"), url(https://talkmore.no/Content/fonts/CircularProBook.ttf) format("truetype"), url(https://talkmore.no/Content/fonts/CircularProBook.svg#CircularProBook) format("svg");
                font-weight: 400;
                font-style: normal;
                font-display: swap;
              }
              @font-face {
                font-family: CircularProBold;
                src: url(https://talkmore.no/Content/fonts/CircularProBold.eot);
                src: url(https://talkmore.no/Content/fonts/CircularProBold.eot) format("embedded-opentype"), url(https://talkmore.no/Content/fonts/CircularProBold.woff2) format("woff2"), url(https://talkmore.no/Content/fonts/CircularProBold.woff) format("woff"), url(https://talkmore.no/Content/fonts/CircularProBold.ttf) format("truetype"), url(https://talkmore.no/Content/fonts/CircularProBold.svg#CircularProBold) format("svg");
                font-weight: 400;
                font-style: normal;
                font-display: swap;
              }`,
    button: `
    min-height: 54px;
    word-break: break-word;
    font-size: 19.5px;
    line-height: 1.75;
    color: black;
    -webkit-font-smoothing: antialiased;

    font-family: CircularProBold, Helvetica, Arial, sans-serif;
    position: relative;
    border: none;
    background: linear-gradient(326.89deg, #57dd1c 34.17%, #82ee40 76.61%),
      #78d54a;

    border-radius: 16px;
    padding: 16px 24px;
    line-height: 145%!important;
    font-size: 22px!important;

    cursor: pointer;
    `,
  },
  evokedset: {
    baseColour: 'blue',
    logoImage: evokedSetLogo,
    fonts: '',
    button: '',
  },
};

// TODO: Better names for standard and alternative

export const themeVariables = {
  baseColour: `linear-gradient(326.89deg, #57dd1c 34.17%, #82ee40 76.61%), #78d54a;`,
  standard: talkmoreStandard,
  alternative: alternative,
};

export const talkMore = `
    background: url("https://talkmore.no/-/media/images/900x500/900x500_baksetet.ashx?h=500&w=900&la=en&hash=5D7BC86E317841C70876F83ABE829B0246207D29")
    center center no-repeat;
    background-size: cover;
`;

export const hurtigruten = `
    background: url("https://www.hurtigruten.co.uk/Public/assets/images/branding/logo/Hurtigruten_logo_Coastal.svg?v=1")
    center center no-repeat;
    border: 3px solid red;
    background-size: cover;
`;
