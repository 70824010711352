import React from 'react';
import { string } from 'prop-types';

const Loading = ({ label }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        width: '100vw',
      }}
    >
      <div style={{ minHeight: 200, minWidth: '50%' }}>
        <h1 style={{ color: 'hotpink', width: 'auto', textAlign: 'center' }}>
          {label}
        </h1>
      </div>
    </div>
  );
};

Loading.propTypes = {
  label: string.isRequired,
};

export default Loading;
