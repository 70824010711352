/**
 * This sets up a very lightweight App State based on React Context API
 * with a familiar reducer / actions / dispatcher syntax, and also exposes
 * this to Redux Devtools which is good and not bad.
 * More info here: https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c
 * ...and here: https://github.com/troch/reinspect
 */

import React, { createContext, useContext } from 'react';
import { useReducer } from 'reinspect';

const initState = {
  quiz: {},
  lastPageStatus: null,
  feedback: null,
  answers: null,
  gameTime: 0,
  themeVariant: 'standard',
  client: 'evokedset',
  status: {
    currentPage: 'intro',
    currentQuestion: -1,
  },
  menuHeaderActive: false,
  frontPageLayout: 'default',
  questionsLayout: 'default',
};

export const configuredReducer = (state, action) => {
  switch (action.type) {
    case 'setBackgroundImage': {
      return {
        ...state,
        bgImage: action.payload,
      };
    }
    case 'setThemeVariant': {
      return {
        ...state,
        themeVariant: action.payload,
      };
    }
    case 'setClient': {
      return {
        ...state,
        client: action.payload,
      };
    }
    case 'setFrontPageLayout': {
      return {
        ...state,
        frontPageLayout: action.payload,
      };
    }
    case 'setQuestionsLayout': {
      return {
        ...state,
        questionsLayout: action.payload,
      };
    }
    case 'setQuiz': {
      return {
        ...state,
        quiz: action.payload,
      };
    }
    case 'setLastPageStatus': {
      return {
        ...state,
        lastPageStatus: action.payload,
      };
    }
    case 'setStatus': {
      return {
        ...state,
        status: action.payload,
      };
    }
    case 'setFeedback': {
      return {
        ...state,
        feedback: action.payload,
      };
    }
    case 'setAnswers': {
      return {
        ...state,
        answers: action.payload,
      };
    }
    case 'setGameTime': {
      return {
        ...state,
        gameTime: action.payload,
      };
    }
    case 'setMenuHeaderActive': {
      return {
        ...state,
        // menuHeader: action.payload,
        menuHeaderActive: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export const StateContext = createContext();
export const StateProvider = ({ children }) => <StateContext.Provider value={useReducer(configuredReducer, initState, undefined, 'app-reducer')}>{children}</StateContext.Provider>;
export const useStateValue = () => useContext(StateContext);
