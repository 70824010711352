import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { themeVariables } from './../styles/themeStyles/index';

// TODO: If we're to use a button component for the entire site, it needs
// to be more intelligent.

// Talkmore:

const ButtonStyle = styled.button`
  min-height: 54px;
  word-break: break-word;
  font-size: 19.5px;
  line-height: 1.75;
  color: black;
  -webkit-font-smoothing: antialiased;

  font-family: CircularProBold, Helvetica, Arial, sans-serif;
  position: relative;
  border: none;
  /* Universalise this button */
  /* background: ${themeVariables.baseColour} */
  background: blue;
  border-radius: 16px;

  transition: 0.2s;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  /* Feedback-button */

  border: 3px solid white;
  color: white;
  background: none;
  margin: 1rem 0;
`;

export default function Button({ buttonText, ...props }) {
  return (
    <ButtonStyle {...props}>
      <>{buttonText} »</>
    </ButtonStyle>
  );
}

Button.propTypes = {
  buttonText: string.isRequired,
};
