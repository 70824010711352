/* Strips slashes (/) from beginning and end of input string.
 * "/some-directory" => "some-directory"
 * "/internal/slash/" => "internal/slash"
 */
export const trimSlashes = str => str.replace(/^\/+|\/+$/g, '');

export const getURLParam = key => {
  if (!window) return;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key);
};

// https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
export const dangerouslyGetMarkup = html => ({
  __html: html,
});

export const slugify = text =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

export const localLayoutConfig = { frontPage: 'centered', questions: 'centered' };
