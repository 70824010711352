import React, { useState } from 'react';
import { any, func } from 'prop-types';
import styled from 'styled-components';
import { dangerouslyGetMarkup } from './lib/utils';
import { useStateValue } from './lib/state';
import { variables } from './styles/global/variables';

//FIXME: Wat? ⬇
const talkmoreAnswerStyles = `
  background: linear-gradient(326.89deg, #57dd1c 34.17%, #82ee40 76.61%),
  #78d54a;
  transition: 0.3s;
  &:hover {
    background: #e9f8e2;
  }
`;

const AnswerStyle = styled.div`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    /* ${talkmoreAnswerStyles} */
    border: 3px solid red;
    font-size: 24px;
    /* background:  */
    background: #fff;
    color: #000;
    border: 1px solid #000;
    &:hover {
      background: #000;
      color: #fff;
    }
  }
  &.full {
    display: flex;
    flex-grow: 1;
    > div {
      flex-grow: 1;
      flex-basis: 0;
      border-top: 3px solid white;
      border-right: 4px solid white;

      &:last-child {
        border-right: none;
      }
    }

    @media (max-width: ${p => p.v.small}px) {
      /* border: 3px solid red; */
      flex-direction: column;
      > div {
        border: none;
        border-top: 3px solid white;
      }
    }
  }

  &.centered {
    display: grid;
    gap: 1rem;
    > div {
      border-radius: 1rem;
      // Hurtigruten
      border-radius: 0;
    }
  }
  &.right {
    /* border: 3px solid red; */
    display: flex;
    flex-direction: column;
    /* justify-content: stretch; */
    /* align-items: stretch; */
    > div {
      /* border: 3px solid yellow; */
      flex-grow: 1;
      /* justify-content: stretch; */
    }
    /* border: 3px solid red; */
  }
`;

// NOTE: Removed everything related to 'AnswerBlockStyle' as I could not find it in use anywhere
// Also: Let's triplecheck everything related to selecting answer as there was quite a bit of
// confusing use of the same variable name for different things going on in those if-blocks
const Answers = ({ selectAnswer: selectAnswerFromProps, answers }) => {
  const [{ questionsLayout }] = useStateValue();
  const [answer, setAnswer] = useState(null);

  function selectAnswer(event, answer) {
    setAnswer(answer);
    selectAnswerFromProps(answer);
  }
  const answerList = answers.map((item, index) => {
    let answerElement = null;
    if (item.answer[0].acf_fc_layout === 'text') {
      answerElement = <span dangerouslySetInnerHTML={dangerouslyGetMarkup(item.answer[0].text)} />;
    }
    if (item.answer[0].acf_fc_layout === 'image') {
      answerElement = <img src={item.answer[0].image.url} alt={index} />;
    }
    return (
      <div className={answer === item ? 'selected' : '' + item.answer[0].acf_fc_layout + ' answer-block'} key={JSON.stringify(item)} onClick={ev => selectAnswer(ev, item)}>
        {answerElement}
      </div>
    );
  });
  const answersPerRow = answerList.length === 3 || answerList.length >= 5 ? 3 : 2;
  let answerCountClass = 'et-answers-per-row-' + answersPerRow;
  return (
    <AnswerStyle v={variables.mediaqueries} className={answerCountClass + ' et-answers-count-' + answerList.length + ' et-answers' + ` ${questionsLayout}`}>
      {answerList}
    </AnswerStyle>
  );
};

Answers.propTypes = {
  selectAnswer: func.isRequired,
  answers: any.isRequired,
  status: any.isRequired,
};

export default Answers;
