import React from 'react';
import PropTypes from 'prop-types';

import VideoPlayer from './components/VideoPlayer';

import { dangerouslyGetMarkup } from './lib/utils';
import styled from 'styled-components';

const ContentBlockStyle = styled.div`
  /* border: 3px solid greenyellow; */

  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  > * {
    /* flex-grow: 1;
      flex-basis: 0;
      border: 3px solid red; */
  }
`;

//REVIEW: Just ... review.
// The if, if, if, return null-chain seems like it could be written in a more readable way.
const ContentBlock = ({ item, type }) => {
  if (!item) {
    return null;
  }

  return (
    <ContentBlockStyle className={`${type} content-block-container`}>
      {item.map(row => {
        if (row.acf_fc_layout === 'editor') {
          return (
            <React.Fragment key={JSON.stringify(row)}>
              {/* <div
              className="content-block content-block-simple_editor"
              key={JSON.stringify(row)}
            > */}

              {/* <div className="content-block-item text"> */}
              <div dangerouslySetInnerHTML={dangerouslyGetMarkup(row.editor)} />
              {/* </div> */}
              {/* </div> */}
            </React.Fragment>
          );
        }
        if (row.acf_fc_layout === 'video_fullscreen') {
          return (
            // <div
            //   className="content-block content-block-video_fullscreen content-block-simple_editor"
            //   key={JSON.stringify(row)}
            // >
            <React.Fragment key={JSON.stringify(row)}>
              {/* <div className="content-block-item text"> */}
              <div dangerouslySetInnerHTML={dangerouslyGetMarkup(row.editor)} />
              {/* </div> */}
            </React.Fragment>
            // </div>
          );
        }
        if (row.acf_fc_layout === 'image_editor') {
          return (
            // <div
            //   className="content-block content-block-image"
            //   key={JSON.stringify(row)}
            // >
            <React.Fragment key={JSON.stringify(row)}>
              {/* <div className="content-block-item image"> */}
              <img src={row.image.url} alt="" />
              {/* </div> */}
              {/* <div className="content-block-item text"> */}
              <div dangerouslySetInnerHTML={dangerouslyGetMarkup(row.editor)} />
              {/* </div> */}
            </React.Fragment>
            // </div>
          );
        }
        if (row.acf_fc_layout === 'video_editor') {
          return (
            // <div
            //   className="content-block content-block-video"
            //   key={JSON.stringify(row)}
            // >
            <React.Fragment key={JSON.stringify(row)}>
              <div className="content-block-item video">
                <VideoPlayer sources={[{ src: row.video, type: 'video/mp4' }]} autoPlay={false} key={row.video} />
              </div>
              <div className="content-block-item text">
                <div dangerouslySetInnerHTML={dangerouslyGetMarkup(row.editor)} />
              </div>
            </React.Fragment>
            // </div>
          );
        }
        return null;
      })}
    </ContentBlockStyle>
  );
};

ContentBlock.propTypes = {
  item: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default ContentBlock;
