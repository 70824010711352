import React, { useState, useEffect } from 'react';
import { object, func, array, number } from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import ContentBlock from '../ContentBlock';
import SubmissionForm from '../SubmissionForm';
import { dangerouslyGetMarkup, slugify } from '../lib/utils';
import { useStateValue } from '../lib/state';

import { shareQuiz, trackEvent } from './Content.helpers';

const talkMorestyles = `
    background: url("https://talkmore.no/-/media/images/900x500/900x500_baksetet.ashx?h=500&w=900&la=en&hash=5D7BC86E317841C70876F83ABE829B0246207D29")
    center center no-repeat;
    background-size: cover;
`;
const hurtigruteStyles = `
    
    background: url("https://www.hurtigruten.no/globalassets/photos/destinations/nwp/2500x1250_ms-fram-i-ilulissat-gronland-hgr-124938--foto_stian__klo.jpg?width=2500&height=1500&transform=DownFill")
    center center no-repeat;
    background-size: cover;

`;

const QuizContentStyles = styled.div`
  flex-grow: 1;
  img {
    max-width: 75%;
  }
  display: flex;
  justify-content: center;
  align-items: center;

  /* ${talkMorestyles} */
  ${hurtigruteStyles}
  &.full {
    > div {
      background: rgba(255, 255, 255, 0.9);
      padding: 2rem;
    }
  }
  &.centered {
    > div {
      background: white;
      padding: 2rem 2rem 3rem;
      border-radius: 1rem;
      // Hurtigruten
      border-radius: 0;

      > div {
        border: none;
      }
    }
    .content {
      flex-basis: 500px;
      margin: 1rem;
      /* min-height: 250px; */
      display: grid;
      /* padding-bottom: 3rem; */
    }
  }
  &.right {
    /* border: 3px solid red; */
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

    > div {
      background: rgba(255, 255, 255, 0.9);
      flex-grow: 1;
      padding: 2rem;
      display: flex;
    }
  }
`;

const Content = ({ answers, gameTime, quiz, status, changeState, tracker }) => {
  const [{ frontPageLayout }] = useStateValue();
  // console.log({ frontPageLayout });
  const [customBodyClasses, setCustomBodyClasses] = useState([]);
  useEffect(() => {
    // do some setup
    return () => {
      document.querySelector('body').classList.remove(...customBodyClasses);
    };
  });

  // FIXME: These feedback functions are TOO DAMN LONG and unreadable.
  function getTypeQuizFeedbackObject() {
    const answerCounts = [];
    answers.forEach(answer => {
      //TODO: ⬇ answers.answer.answer.answer_type is not iterable on some occasions. Figure it out. (tip: Tiffany Aching)
      if (!answer.answer.answer_type) return;
      answer.answer.answer_type.forEach(answerChoiceType => {
        if (answerCounts[answerChoiceType]) {
          answerCounts[answerChoiceType].count += 1;
        } else {
          let answerGroup = null;
          quiz.types.forEach(type => {
            type.type_names.forEach(typeName => {
              if (typeName.type_name === answerChoiceType) {
                answerGroup = type.type_group;
              }
            });
          });

          answerCounts[answerChoiceType] = {
            count: 1,
            answer_type: answerChoiceType,
            answer_group: answerGroup,
          };
        }
      });
    });

    const resultAnswerTypes = {};

    Object.values(answerCounts).forEach(answerCount => {
      if (!resultAnswerTypes[answerCount.answer_group]) {
        resultAnswerTypes[answerCount.answer_group] = answerCount;
      } else if (resultAnswerTypes[answerCount.answer_group].count < answerCount.count) {
        resultAnswerTypes[answerCount.answer_group] = answerCount;
      }
    });

    // Add resultAnswerTypes as body classes
    _.each(resultAnswerTypes, answerType => {
      const resultClassName = `et-engage-type-${slugify(answerType.answer_group)}-${slugify(answerType.answer_type)}`;
      document.querySelector('body').classList.add(resultClassName);
      setCustomBodyClasses([resultClassName, ...customBodyClasses]);
    });

    let feedback = [];

    if (quiz.type_feedback_type === 'combined') {
      let text = quiz.type_feedback_combined;

      _.each(resultAnswerTypes, answerType => {
        const regex = new RegExp(`{${answerType.answer_group}}`, 'g');
        text = text.replace(regex, answerType.answer_type);
      });

      feedback[0] = {
        type_feedback_name: 'combined',
        type_feedback: text,
      };
    } else if (quiz.type_feedback) {
      feedback = quiz.type_feedback.filter(type => {
        let count = 0;
        Object.values(resultAnswerTypes).forEach(resultAnswerType => {
          if (type.type_feedback_names.indexOf(resultAnswerType.answer_type) !== -1) {
            count++;
          }
        });

        return count === Object.values(resultAnswerTypes).length && count === type.type_feedback_names.length;
      });
    }
    return feedback;
  }

  function getTypeQuizFeedback() {
    let pageContent = null;
    let feedbackFormJSX = null;
    const feedback = getTypeQuizFeedbackObject();

    if (quiz.show_submission_form) {
      feedbackFormJSX = (
        <div className="et-feedback-form">
          <SubmissionForm quiz={quiz} tracker={tracker} answers={answers} status={status} feedback={feedback[0]} changeState={changeState} />
        </div>
      );
    }

    if (feedback.length > 0) {
      trackEvent(`ShowFeedbackPage Type: ${feedback[0].type_feedback_name}`, '');
      pageContent = (
        <div className={`et-page-feedback et-type-${slugify(feedback[0].type_feedback_name)}`}>
          <div dangerouslySetInnerHTML={dangerouslyGetMarkup(feedback[0].type_feedback)} />
          {feedbackFormJSX}
        </div>
      );
    }
    return pageContent;
  }

  function getMemoryGameFeedback() {
    let feedback = null;
    let feedbackFormJSX = null;
    let pageContent = null;
    if (!gameTime) {
      return null;
    }

    if (gameTime / 1000 < parseInt(quiz.time_for_quiz, 10)) {
      feedback = quiz.memory_game_success_feedback;

      if (quiz.show_submission_form) {
        feedbackFormJSX = (
          <div className="et-feedback-form">
            <SubmissionForm quiz={quiz} tracker={tracker} answers={answers} status={status} changeState={changeState} />
          </div>
        );
      }
    } else {
      feedback = quiz.memory_game_failure_feedback;
    }

    if (feedback) {
      pageContent = (
        <div className="et-page-feedback et-memorygame-feedback">
          <div dangerouslySetInnerHTML={dangerouslyGetMarkup(feedback)} />
          {feedbackFormJSX}
        </div>
      );
    }
    return pageContent;
  }

  function getStandardQuizFeedback() {
    let feedbackJSX = null;
    let feedbackFormJSX = null;
    let pageContent = null;
    let feedback = null;

    if (!quiz.questions) {
      feedbackFormJSX = (
        <div className="et-feedback-form">
          <SubmissionForm quiz={quiz} tracker={tracker} answers={answers} status={status} changeState={changeState} />
        </div>
      );
    } else {
      const correct = answers.filter(answer => answer.answer.is_correct);
      feedback = _.find(quiz.feedback, f => parseInt(f.maximum_correct_answers, 10) >= correct.length && parseInt(f.minimum_correct_answers, 10) <= correct.length);

      const action = `ShowFeedbackPage, Correct - ${correct.length}`;
      trackEvent(action, '');

      if (feedback && feedback.feedback) {
        feedback.feedback = feedback.feedback.replace('[et_correct]', `<span class="correct">${correct.length}</span>`);
        feedbackJSX = <div dangerouslySetInnerHTML={dangerouslyGetMarkup(feedback.feedback)} />;
      } else {
        feedbackJSX = <div className="et-feedback-default" />;
      }
      if (correct.length >= parseInt(quiz.feedback_success_count, 10)) {
        feedbackFormJSX = (
          <div className="et-feedback-form">
            <SubmissionForm quiz={quiz} tracker={tracker} answers={answers} status={status} changeState={changeState} />
          </div>
        );
      }
    }

    pageContent = (
      <div className="et-page-feedback">
        {feedbackJSX}
        {feedbackFormJSX}
      </div>
    );
    return pageContent;
  }

  function getFeedback() {
    const { quiz_type: quizType } = quiz;
    if (quizType === 'standard-quiz') {
      return getStandardQuizFeedback();
    }
    if (quizType === 'type-quiz') {
      return getTypeQuizFeedback();
    }
    if (quizType === 'memory-game') {
      return getMemoryGameFeedback();
    }
    return null;
  }

  function handleClick(event) {
    if (event.target.hasAttribute('href')) {
      return true;
    }
    // REVIEW: What's up with this block?
    let el = event.target;
    const els = [];
    while (el) {
      if (el && el.hasAttribute && el.hasAttribute('href')) {
        if (!el.getAttribute('target')) {
          window.location.href = el.getAttribute('href');
        } else {
          window.open(el.getAttribute('href'), el.getAttribute('target'));
        }
      }
      els.unshift(el);
      el = el.parentNode;
    }
    event.preventDefault();
    //TODO: ⬇ Shorten this bit
    if (event.target.hasAttribute('data-evoketools-start-quiz')) {
      changeState('startQuiz');
    }
    if (event.target.hasAttribute('data-evoketools-restart-quiz')) {
      changeState('restartQuiz');
    }
    if (event.target.hasAttribute('data-evoketools-next-page')) {
      changeState('nextPage');
    }
    if (event.target.hasAttribute('data-evoketools-close-page')) {
      changeState('closePage');
    }
    if (event.target.hasAttribute('data-evoketools-share-quiz')) {
      shareQuiz(quiz);
    }
    if (event.target.hasAttribute('data-evoketools-redirect-quiz')) {
      changeState('redirectQuiz');
    }
    if (event.target.hasAttribute('data-evoketools-link-page')) {
      const pageName = event.target.getAttribute('data-evoketools-link-page');
      const page = _.find(quiz.content_pages, single => single.page_title === pageName);
      if (page) {
        changeState('page', page);
      }
    }
  }

  // Everything from the class component's render function below this line
  let pageContent = '';
  let contentTypeClassName = '';

  // Allowed page types
  const allowed = ['intro', 'finish', 'page', 'feedback'];

  if (allowed.indexOf(status.currentPage) === -1) return null;
  /**
   *
   * Intro Page
   */
  if (status.currentPage === 'intro') {
    if (!quiz.content_intro_page) {
      return null;
    }
    pageContent = <ContentBlock item={quiz.content_intro_page} type="intro" />;
    contentTypeClassName = 'content-intro';
  }

  /**
   * Pages
   */
  if (status.currentPage === 'page') {
    if (!status.pageContent) {
      return null;
    }
    pageContent = <ContentBlock item={status.pageContent.content_page} type="page" />;
    contentTypeClassName = `content-page content-page-${status.pageContent.page_title}`;
  }

  /**
   *
   * Feedback
   */
  if (status.currentPage === 'feedback') {
    tracker.sendPageView({
      pageValues: { name: 'quiz_finished', level: 3 },
    });
    pageContent = getFeedback();
    contentTypeClassName = 'content-feedback';
  }

  /**
   *
   * FINISH
   */
  if (status.currentPage === 'finish') {
    if (!quiz.content_finish_page) {
      return null;
    }
    pageContent = <ContentBlock item={quiz.content_finish_page} type="finish" />;
    contentTypeClassName = 'content-finish';
  }
  return (
    <QuizContentStyles className={`et-content ${frontPageLayout}`}>
      <div className={`content ${contentTypeClassName}`} onClick={handleClick}>
        {pageContent}
      </div>
    </QuizContentStyles>
  );
};

Content.defaultProps = {
  tracker: undefined,
  answers: null,
  gameTime: null,
};

Content.propTypes = {
  tracker: object,
  answers: array,
  gameTime: number,
  quiz: object.isRequired,
  status: object.isRequired,
  changeState: func.isRequired,
};

export default Content;
