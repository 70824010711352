import React, { Component } from 'react';
import { any } from 'prop-types';
import styled from 'styled-components';

//REVIEW:
//TODO Low hanging fruit. Rewrite this as a functional component

// TODO: Find a home for this styling too

const ProgressBarStyle = styled.div`
  border: 3px solid red;
  display: none;
`;
class ProgressBar extends Component {
  render() {
    if (this.props.status.currentPage !== 'question') {
      return null;
    }

    if (!this.props.quiz.show_progress_bar) {
      return null;
    }

    let total = this.props.quiz.questions.length;
    let current = this.props.status.currentQuestion;

    let items = [];
    for (let index = 0; index < total; index++) {
      if (current < index) {
        items.push(
          <div key={index} className='progress-bar-item unanswered'>
            {index + 1}
          </div>
        );
      }

      if (current === index) {
        items.push(
          <div key={index} className='progress-bar-item current'>
            {index + 1}
          </div>
        );
      }

      if (current > index) {
        items.push(
          <div key={index} className='progress-bar-item complete'>
            {index + 1}
          </div>
        );
      }
    }

    return (
      <ProgressBarStyle className='et-progress-bar'>
        <div className='progress-bar-content'>{items}</div>
      </ProgressBarStyle>
    );
  }
}

export default ProgressBar;

//TODO: Types

ProgressBar.propTypes = {
  status: any.isRequired,
  quiz: any.isRequired,
};
