import React, { useState, useEffect, useRef } from 'react';
import { string, object } from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';

import Quiz from '../Quiz';
import Header from '../Header';
import Content from '../Content';
import MemoryGame from '../MemoryGame';
import ProgressBar from '../ProgressBar';
import InstantFeedback from '../InstantFeedback';

import { slugify } from '../lib/utils';
import { useStateValue } from '../lib/state';

import { addBodyClassHelper, resetCSSClassHelper, clearQuestionClassHelper, trackAnswerEvent, trackStateChange } from './ETQuiz.helpers';

import styled from 'styled-components';
import { clientVars } from '../styles/themeStyles';

// NOTE: @staale: Moved all your styled-component blocks outside of their respective main component functions.
// Styled declarations go either in the same place as prop-types; on the top level of the component, or in their own
// files and imported to the top level, like all other imports ;) -m

// Import global styles based on client
// Render fonts based on client
// Render button style based on component

const GlobalStyles = styled.div`
  font-family: sans-serif;
  @font-face {
    font-family: 'Atlas Grotesk';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(https://hurtigruten.co.uk/Content/Fonts/AtlasGrotesk-Regular-Web.woff2) format('woff2'), url(https://hurtigruten.co.uk/Content/Fonts/AtlasGrotesk-Regular-Web.woff) format('woff');
  }

  @font-face {
    font-family: 'Atlas Grotesk';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url(https://hurtigruten.co.uk/Content/Fonts/AtlasGrotesk-Medium-Web.woff2) format('woff2'), url(https://hurtigruten.co.uk/Content/Fonts/AtlasGrotesk-Medium-Web.woff) format('woff');
  }

  @font-face {
    font-family: 'Atlas Grotesk';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(https://hurtigruten.co.uk/Content/Fonts/AtlasGrotesk-Bold-Web.woff2) format('woff2'), url(https://hurtigruten.co.uk/Content/Fonts/AtlasGrotesk-Bold-Web.woff) format('woff');
  }

  @font-face {
    font-family: 'Atlas Grotesk';
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: url(https://hurtigruten.co.uk/Content/Fonts/AtlasGrotesk-Black-Web.woff2) format('woff2'), url(https://hurtigruten.co.uk/Content/Fonts/AtlasGrotesk-Black-Web.woff) format('woff');
  }

  // Fonts
  /* ${p => p.currentClientVars.fonts} */
  // Button
  button {
    /* ${p => p.currentClientVars.button} */
  }
  /* font-family: Atlas Grotesk; */
  button {
    border: none;
    text-align: center;
    padding: 14px 30px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;

    font-family: Atlas Grotesk;
    font-weight: 500;
    line-height: 1.4;
    font-size: clamp(16px, 1.5vw, 16px);
    font-size: 20px;
    background: rgb(224, 28, 31);
    color: rgb(255, 255, 255);
    font-family: sans-serif;
  }
`;

// alert(client);
const QuizWrapperStyles = styled.div`
  /* background: rgba(255, 255, 2, 0.3); */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ETQuiz = ({ url, quizId, tracker, client, styleConfig, themeConfig, backgroundImage }) => {
  // ⬇ Local state
  // const [extraClasses, setExtraClasses] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [customBodyClasses, setCustomBodyClasses] = useState([]);
  const [questionBodyClasses, setQuestionBodyClasses] = useState([]);
  // ⬇ App state
  const [{ quiz, answers, status, gameTime, feedback, lastPageStatus }, dispatch] = useStateValue();

  // Refs
  // TODO: Get rid of these
  // const quizRef = useRef(null);
  const memoryGameRef = useRef(null);

  const addBodyClasses = () => addBodyClassHelper(quiz);
  const resetClasses = () => resetCSSClassHelper(customBodyClasses, setCustomBodyClasses);
  const clearQuestionClasses = () => clearQuestionClassHelper(questionBodyClasses, setQuestionBodyClasses);

  /**
   * useEffect with an empty dependencies array replaces componentDidMount
   */
  useEffect(() => {
    tracker && tracker.sendPageView({ pageValues: { name: 'landed', level: 1 } });
    //TODO: Handle errors ⬇
    fetch(`${url}?action=etQuiz&quiz=${quizId}`)
      .then(res => res.json())
      .then(resJson => {
        dispatch({
          type: 'setQuiz',
          payload: resJson.data,
        });
        if (resJson?.data?.is_full_screen) {
          /* setExtraClasses('rubber-band'); */
        }
        addBodyClasses();
        const frontPageLayout = styleConfig?.frontPage || 'no-layout';
        const questionsLayout = styleConfig?.questions || 'no-layout';
        const themeVariant = themeConfig || 'standard';
        const bgImage = backgroundImage || 'standard';
        const theClient = client || 'standard';
        // console.log({ client });
        dispatch({ type: 'setFrontPageLayout', payload: frontPageLayout });
        dispatch({ type: 'setQuestionsLayout', payload: questionsLayout });
        dispatch({ type: 'setThemeVariant', payload: themeVariant });
        dispatch({ type: 'setBackgroundImage', payload: bgImage });
        dispatch({ type: 'setClient', payload: theClient });
      });
  }, []);

  function showQuestion(questionIndex) {
    dispatch({
      type: 'setStatus',
      payload: { currentPage: 'question', currentQuestion: questionIndex },
    });
    clearQuestionClasses();
    const questionNumberClass = `et-engage-question-${questionIndex}`;
    // Add an adhoc class to the body for this question
    document.querySelector('body').classList.add(questionNumberClass);
    setQuestionBodyClasses([...questionBodyClasses, questionNumberClass]);
  }

  function showFinishPage() {
    if (!quiz.redirect_on_finish_url) {
      dispatch({
        type: 'setStatus',
        payload: {
          currentPage: 'finish',
          currentQuestion: status.currentQuestion - 1,
        },
      });
      return;
    }
    let finishUrl = quiz.redirect_on_finish_url;
    if (quiz.include_query_params_in_redirect_on_finish_url) {
      if (finishUrl.indexOf('?') === -1) {
        finishUrl += window.location.search;
      } else {
        finishUrl += `&${window.location.search.substring(1)}`;
      }
    }
    window.location = finishUrl;
  }

  function saveAndProceed(answer) {
    window.scrollTo(0, 0);
    resetClasses();
    // REVIEW: Safe to delete? ⬇
    // if (quizRef?.current) quizRef.current.onQuestionChange();
    const newAnswers = answers ? [...answers] : []; // eslint-disable-line
    newAnswers.push({
      question: quiz.questions[status.currentQuestion],
      answer,
    });
    dispatch({ type: 'setFeedback', payload: null });
    dispatch({ type: 'setAnswers', payload: newAnswers });

    if (newAnswers.length < quiz.questions.length) {
      // Show the next question's video if there's one
      showQuestion(++status.currentQuestion);
      if (window.engageVideoPlay) {
        window.engageVideoPlay();
      }
    } else {
      clearQuestionClasses();
      if (quiz.quiz_type === 'type-quiz' || quiz.show_feedback_page) {
        dispatch({
          type: 'setStatus',
          payload: {
            currentPage: 'feedback',
            currentQuestion: status.currentQuestion - 1,
          },
        });
      } else {
        showFinishPage();
      }
    }
  }

  function restartMemoryGame() {
    dispatch({
      type: 'setStatus',
      payload: {
        currentPage: 'memory-game',
        currentQuestion: status.currentQuestion - 1,
      },
    });
    dispatch({ type: 'setGameTime', payload: 0 });
    if (memoryGameRef?.current) memoryGameRef.current.restartGame();
  }

  //TODO: Clean up, move out
  const navigationFuncs = {
    intro: () => {
      clearQuestionClasses();
      dispatch({ type: 'setFeedback', payload: null });
      dispatch({ type: 'setAnswers', payload: null });
      dispatch({ type: 'setGameTime', payload: 0 });
      dispatch({
        type: 'setStatus',
        payload: {
          currentPage: 'intro',
          currentQuestion: status.currentQuestion - 1,
        },
      });
    },
    startQuiz: () => {
      tracker && tracker.sendPageView({ pageValues: { name: 'quiz_start', level: 2 } });

      if (['standard-quiz', 'type-quiz'].includes(quiz.quiz_type)) {
        if (quiz.questions && quiz.questions.length > 0) {
          dispatch({
            type: 'setAnswers',
            answers: null,
          });
          showQuestion(0);
          return;
        }
        const nextPage = quiz.show_feedback_page ? 'feedback' : 'finish';
        dispatch({
          type: 'setStatus',
          payload: {
            currentPage: nextPage,
            currentQuestion: status.currentQuestion - 1,
          },
        });
        return;
      }
      if (quiz.quiz_type === 'memory-game') {
        restartMemoryGame();
      }
    },
    page: page => {
      if (status.currentPage !== 'page') {
        dispatch({ type: 'setLastPageStatus', payload: { ...status } });
      }
      dispatch({
        type: 'setStatus',
        payload: { currentPage: 'page', pageContent: page },
      });
    },
    closePage: () => {
      if (!lastPageStatus) {
        return;
      }
      dispatch({
        type: 'setStatus',
        payload: { ...lastPageStatus },
      });
    },
    nextPage: () => {
      if (status.currentPage === 'intro') {
        if (['standard-quiz', 'type-quiz'].indexOf(quiz.quiz_type) !== -1) {
          if (quiz.questions && quiz.questions.length > 0) {
            showQuestion(0);
            return;
          }
          const nextPage = quiz.show_feedback_page ? 'feedback' : 'finish';
          dispatch({
            type: 'setStatus',
            payload: {
              currentPage: nextPage,
              currentQuestion: status.currentQuestion - 1,
            },
          });
          return;
        }
        if (quiz.quiz_type === 'memory-game') {
          restartMemoryGame();
          return;
        }
      }
      if (status.currentPage === 'feedback') {
        showFinishPage();
      }
    },
    answer: answer => {
      if (tracker) {
        const answerEvent = {
          answerText: answer?.answer[0]?.text || 'no text',
          answerFeedback: answer?.answer_feedback || 'no feedback',
          answerCorrect: answer?.is_correct,
          questionIndex: status?.currentQuestion,
        };
        tracker.sendEvent({
          eventType: 'question_answered',
          value: { answerEvent },
        });
      }
      if (quiz.quiz_type === 'standard-quiz') {
        trackAnswerEvent(status.currentQuestion, answer.is_correct);
      }
      let feedbackDelay = 500;
      if (quiz.show_next_button) {
        feedbackDelay = 10;
      }
      if (quiz.show_instant_feedback) {
        feedbackDelay = 2000;
        if (quiz.instant_feedback_time) {
          feedbackDelay = parseInt(quiz.instant_feedback_time, 10);
        }
        window.scrollTo(0, 0);
        const currentQuestion = quiz.questions[status.currentQuestion];
        let feedbackMessage = answer.is_correct ? 'Riktig' : 'Galt';

        if (currentQuestion.show_custom_feedback) {
          feedbackMessage = answer.is_correct ? currentQuestion.feedback_correct : currentQuestion.feedback_incorrect;
        }
        if (answer.answer_feedback) {
          feedbackMessage = answer.answer_feedback;
        }
        let feedbackCssClass = '';
        if (answer.answer_type) {
          feedbackCssClass = `type-${slugify(answer.answer_type.join('-'))}`;
        }
        dispatch({
          type: 'setFeedback',
          payload: {
            correct: answer.is_correct,
            message: feedbackMessage,
            cssClass: feedbackCssClass,
          },
        });
      }
      setCurrentAnswer(answer);
      if (!quiz.show_instant_feedback) {
        window.setTimeout(() => {
          saveAndProceed(answer);
        }, feedbackDelay);
      } else if (!quiz.show_instant_feedback_next_button) {
        window.setTimeout(() => {
          saveAndProceed(answer);
        }, feedbackDelay);
      }
    },
    finishGame: time => {
      dispatch({
        type: 'setStatus',
        payload: { currentPage: 'feedback' },
        gameTime: time,
      });
    },
    restart: () => restartMemoryGame(),
    redirectQuiz: () => showFinishPage(),
  };

  function changeNavState(property, value) {
    resetClasses();
    trackStateChange(property, value);

    if (Object.keys(navigationFuncs).includes(property)) {
      navigationFuncs[property](value);
    }
    if (property !== 'answer') {
      window.scrollTo(0, 0);
    }
  }

  // Perhaps this belongs elsewhere

  const currentClientVars = clientVars[client];
  console.log(currentClientVars);

  return (
    <GlobalStyles className={`et-quiz`} currentClientVars={currentClientVars}>
      <QuizWrapperStyles className="et-quiz-inner">
        <Header navigateHandler={changeNavState} />
        <ProgressBar quiz={quiz} status={status} changeState={changeNavState} />
        <Content tracker={tracker} quiz={quiz} status={status} changeState={changeNavState} answers={answers} gameTime={gameTime} />
        <Quiz quiz={quiz} status={status} changeState={changeNavState} />
        <MemoryGame quiz={quiz} status={status} changeState={changeNavState} ref={memoryGameRef} />
      </QuizWrapperStyles>
      <CSSTransitionGroup transitionName="instant-feedback-transition" transitionEnterTimeout={1} transitionLeaveTimeout={200}>
        {feedback ? (
          <InstantFeedback
            feedback={feedback}
            key="instant-feedback"
            showNextButton={quiz.show_instant_feedback_next_button}
            nextButtonText={quiz.instant_feedback_next_button_text}
            clickNextButton={() => saveAndProceed(currentAnswer)}
          />
        ) : null}
      </CSSTransitionGroup>
    </GlobalStyles>
  );
};

ETQuiz.defaultProps = {
  tracker: undefined,
  styleConfig: undefined,
};

ETQuiz.propTypes = {
  url: string.isRequired,
  quizId: string.isRequired,
  tracker: object,
  styleConfig: object,
  client: string,
  themeConfig: object,
  backgroundImage: string,
};

export default ETQuiz;
