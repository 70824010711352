/**
 * DOM manipulation helpers.
 * REVIEW: It seems like several of these are keeping a piece
 * of state in sync with the classLists of body. Feels unecessary.
 * Keep an eye open for opportunities to get rid of these.
 */

//REVIEW: @staale Is this property still in use?
export function addBodyClassHelper(quiz) {
  const { predefined_css_classes: predefCssClasses } = quiz;
  if ('ontouchstart' in window) {
    document.getElementsByTagName('body')[0].className += ' et-touch';
  } else {
    document.getElementsByTagName('body')[0].className += ' et-no-touch';
  }
  if (predefCssClasses) {
    document.querySelector('body').classList.add(predefCssClasses);
  }
}

export function resetCSSClassHelper(customBodyClasses, setCustomBodyClasses) {
  document
    .querySelector('body')
    .classList.remove(
      'et-no-overflow-feedback',
      'et-no-overflow-mg',
      'et-no-overflow-answers',
      ...customBodyClasses
    );
  setCustomBodyClasses([]);
}

export function clearQuestionClassHelper(
  questionBodyClasses,
  setQuestionBodyClasses
) {
  if (questionBodyClasses.length > 0) {
    document.querySelector('body').classList.remove(...questionBodyClasses);
    setQuestionBodyClasses([]);
  }
}
/** EOF Dom manipulation Helpers */

/**
 * Tracking helpers
 * Deals with updating Google Analytics and it's ilk
 */
export function trackStateChange(property, value) {
  if (property !== 'answer') {
    const category = `Engage - ${document.title}`;
    const label = '';
    let action = '';
    if (property === 'page') {
      // eslint-disable-next-line prefer-template
      action += ' ' + (value.page_title ? value.page_title : '');
    } else {
      action += property;
    }
    if (window.s) {
      window.s.clearVars();
      window.s.pageName = `${category}:${label}`;
      // eslint-disable-next-line no-void
      void window.s.t();
    }
    if (window.ga) {
      window.ga('send', 'event', category, action, label);
    }
    if (window.fbq) {
      window.fbq('track', 'ViewContent', { value: action });
    }
    if (window.utag) {
      window.utag.view({
        page_name: `${category}:${label}`,
        page_hierarchy: `${category}:${label}`,
      });
    }
  }
}

export function trackAnswerEvent(questionIndex, isCorrect) {
  const category = `Engage - ${document.title}`;
  // eslint-disable-next-line no-nested-ternary
  const eventName = isCorrect
    ? 'Answered Correctly'
    : isCorrect === false
    ? 'Answered Incorrectly'
    : 'Timed Out';

  const action = `Question ${questionIndex}: ${eventName}`;
  const label = '';
  if (window.s) {
    window.s.clearVars();
    window.s.pageName = `${category}:${label}`;
    // eslint-disable-next-line no-void
    void window.s.t();
  }
  if (window.ga) {
    window.ga('send', 'event', category, action, label);
  }
  if (window.fbq) {
    window.fbq('track', 'ViewContent', { value: label });
  }
  if (window.utag) {
    window.utag.view({
      page_name: `${category}:${label}`,
      page_hierarchy: `${category}:${label}`,
    });
  }
}
