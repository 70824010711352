import React, { Component } from 'react';
import { any } from 'prop-types';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elapsed: 0,
    };
  }

  componentDidMount = () => {
    this.timer = window.setInterval(this.tick, 5);
    this.startTime = new Date();
  };

  componentWillUnmount = () => {
    if (this.timer) {
      window.clearInterval(this.timer);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { question: prevQuestion } = prevProps;
    const { question: nextQuestion } = this.props;
    if (prevQuestion !== nextQuestion) {
      this.restartTimer();
    }
  };

  restartTimer = () => {
    if (this.timer) {
      window.clearInterval(this.timer);
    }
    this.startTime = new Date();
    this.setState({
      elapsed: 0,
    });
    this.timer = window.setInterval(this.tick, 5);
  };

  tick = () => {
    this.setState({
      elapsed: new Date() - this.startTime,
    });
    const { elapsed } = this.state;
    const { totalTime, onTimeOver } = this.props;
    if (elapsed >= 1000 * totalTime) {
      window.clearInterval(this.timer);
      this.setState({
        elapsed: 0,
      });
      onTimeOver();
    }
  };

  render() {
    const { elapsed } = this.state;
    const { totalTime } = this.props;
    const percent = elapsed / (10 * totalTime);
    // let red = 0, blue = 0, green = 255;
    const red = 0;
    const green = 122;
    const blue = 208;
    // let red = 13, blue = 165, green = 0; // Better colours

    // green till 60
    // yellow from 70 to 80
    // red from 90 to 100
    // red = Math.round(Math.floor(percent) * 2.56);
    // green = Math.round(Math.floor(100 - percent) * 2.56);

    // Commenting this out to have single colour for timer

    // if( percent > 60 && percent <= 70 ) {
    //   red = Math.round(Math.floor(10 * (percent - 60)) * 2.56);
    //   green = 255;
    // }
    // if( percent > 70 && percent <= 80 ) {
    //   red = 255;
    //   green = 255;
    // }
    // if( percent > 80 && percent <= 90 ) {
    //   red = 255;
    //   green = Math.round(Math.floor(10 * (90 - percent)) * 2.56);
    // }
    // if( percent > 90 ) {
    //   red = 255;
    //   green = 0;
    // }

    const meterStyle = {
      width: `${percent}%`,
      backgroundColor: `rgb(${red},${green},${blue})`,
    };
    return (
      <div className='timer'>
        <div className='timer-progress'>
          <div
            className={`${percent > 80 ? 'danger' : ''} timer-meter`}
            style={meterStyle}
          />
        </div>
      </div>
    );
  }
}

export default Timer;

Timer.propTypes = {
  question: any.isRequired,
  onTimeOver: any.isRequired,
  totalTime: any.isRequired,
};
