import _ from 'lodash';

export function getShareText(quiz) {
  const { quiz_type: quizType, questions, answers } = quiz;
  let feedback;
  let shareText = '';
  let shareTitle = '';
  if (quizType === 'standard-quiz') {
    if (!questions) {
      return { text: '', title: '' };
    }
    const correct = answers.filter(answer => answer.answer.is_correct);
    feedback = _.find(quiz.feedback, f => parseInt(f.maximum_correct_answers, 10) >= correct.length && parseInt(f.minimum_correct_answers, 10) <= correct.length);

    if (feedback) {
      shareText = feedback.share_feedback_text.replace('[et_correct]', correct.length);
      shareTitle = feedback.share_feedback_title.replace('[et_correct]', correct.length);
    }
  }

  if (quizType === 'type-quiz') {
    feedback = this.getTypeQuizFeedbackObject();
    if (feedback.length && feedback[0].type_feedback_share_text) {
      shareText = feedback[0].type_feedback_share_text;
      shareTitle = feedback[0].type_feedback_share_title;
    }
  }

  return { text: shareText, title: shareTitle };
}

export function trackEvent(action, label) {
  // FeedbackPage Event
  const category = `Engage - ${document.title}`;
  if (window.s) {
    window.s.clearVars();
    window.s.pageName = `${category}:${label}`;
    // eslint-disable-next-line no-void
    void window.s.t();
  }
  if (window.ga) {
    window.ga('send', 'event', category, action, label);
  }
  if (window.fbq) {
    window.fbq('track', 'ViewContent', { value: action });
  }
  if (window.utag) {
    window.utag.view({
      page_name: `${category}:${label}`,
      page_hierarchy: `${category}:${label}`,
    });
  }
}

export function shareQuiz(quiz) {
  const shareText = getShareText(quiz);
  let url = window.location.href;
  const regex = /(<([^>]+)>)/gi;
  if (shareText.text) {
    url = `${window.location.origin + window.location.pathname}?sharetext=${encodeURIComponent(shareText.text.replace(regex, ''))}`;
  }
  if (shareText.title) {
    url += `&sharetitle=${encodeURIComponent(shareText.title.replace(regex, ''))}`;
  }

  // eslint-disable-next-line
  if (window.FB && FB.ui) {
    // eslint-disable-next-line
    FB.ui({
      href: url,
      method: 'share',
      description: shareText.text.replace(regex, ''),
    });

    const action = `ShareFacebook - ${shareText.title}`;
    trackEvent(action, '');
  }
}
