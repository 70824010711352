import React from 'react';
import { func } from 'prop-types';
import { useStateValue } from './lib/state';
import styled from 'styled-components';
// import { themeVariables } from './styles/themeStyles';
import { clientVars } from './styles/themeStyles';
// import logo from `./../src/assets/${themename}/talkmore_logo_strom.png`;
import { variables } from './styles/global/variables';
import { Squash } from 'hamburger-react';

const HeaderStyles = styled.header`
  padding: 2rem;
  background: white;

  @media (max-width: ${p => p.v.small}px) {
    padding: 1rem;
  }

  &.${props => props.themeVariant} {
    /* border: 3px solid green; */
  }

  /* ${p => p.themeVariant === 'mobile' && 'border: 3px solid green;'} */

  .et-header-inner {
    > * {
      /* border: 1px solid red; */
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    .logoAndBurger {
      .logoimage {
        display: inline-block;
        background: url(${p => p.currentClientVars.logoImage}) left center no-repeat;
        background-size: contain;
        height: 70px;
        max-width: 200px;
        width: 100%;
        cursor: pointer;
      }
    }
    .menu-button-toggle {
      display: none;
    }
    .menu-pages {
      /* display: none; */
      display: inline-block;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      /* align-self: flex-end; */
    }

    .menu-item {
      font-size: 20px;
      height: 100%;
      cursor: pointer;
      transition: 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      &:hover {
        color: #666;
        background: #eee;
      }
      span {
      }
    }
  }

  @media (max-width: ${p => p.v.small}px) {
    &.active {
      display: flex;
      flex-direction: column;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .et-header-inner {
      display: flex;

      .logoAndBurger {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      .menu-button-toggle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        /* font-size: 3rem; */
      }
      .menu-pages {
        display: none;
      }
    }
    &.active .et-header-inner {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      transition: 1s;
      .menu-pages {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 2rem;
        padding: 2rem 0;
      }
      .menu-item {
        /* background: #eee; */
        padding: 1rem 2rem;
        border-radius: 1rem;
        color: silver;
        &.active {
          border: 3px solid goldenrod;
        }
      }
    }
  }
`;

const Header = ({ navigateHandler }) => {
  const [{ quiz, client, status, menuHeaderActive, themeVariant }, dispatch] = useStateValue();
  const currentClientVars = clientVars[client];
  function openMenuPage(event, page) {
    //REVIEW: Could headerMenuActive be a boolean?
    navigateHandler('page', page);
    dispatch({ type: 'setMenuHeaderActive', payload: 'active' });
    // dispatch({ type: 'setMenuHeaderActive', payload: true });
  }

  function toggleMenu() {
    dispatch({
      type: 'setMenuHeaderActive',
      payload: !menuHeaderActive,
    });
  }

  let menuList = null;
  let hideMenuList = false;

  if (status.currentPage === 'question' && quiz.time_per_question && parseInt(quiz.time_per_question, 10) > 0) {
    hideMenuList = true;
  }

  if (!hideMenuList && quiz.content_pages) {
    menuList = quiz.content_pages.map(page => {
      let activeClass = '';
      if (status.currentPage === 'page' && status.pageContent.page_title === page.page_title) {
        activeClass = 'active';
      }
      return (
        <div key={page.page_title} className={activeClass + ' menu-item'} onClick={event => openMenuPage(event, page)}>
          <span className="menu-item-content">{page.page_title}</span>
        </div>
      );
    });
  }
  return (
    <HeaderStyles themeVariant={themeVariant} currentClientVars={currentClientVars} v={variables.mediaqueries} className={`et-header ${themeVariant} ${menuHeaderActive ? 'active' : ''}`}>
      {/* {console.log(currentClientVars)} */}
      <div className={`et-header-inner`}>
        <div onClick={() => navigateHandler('intro')} className="logoAndBurger ">
          {/* <img src="https://talkmore.no/-/media/images/ikoner-og-logoer/logoer/2021/talkmore-logo.ashx?h=35&w=200&la=en&hash=7846E5419C0A41D36CE3DC6F880E628A9F5804B6" /> */}
          {/* {quiz.title} */}
          <div className="logoimage" />

          {!hideMenuList && quiz.content_pages && quiz.content_pages.length > 0 ? (
            <div className="menu-button-toggle" onClick={toggleMenu}>
              <Squash />
            </div>
          ) : null}
        </div>
        <div className={`menu-pages ${menuHeaderActive ? 'active' : ''}`}>{menuList}</div>
      </div>
    </HeaderStyles>
  );
};

export default Header;

Header.propTypes = {
  navigateHandler: func.isRequired,
};
