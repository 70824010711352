import React from 'react';
import ReactDOM from 'react-dom';
import { StateInspector } from 'reinspect';
import { TrackBot } from '@evokedset/orwell';

import { StateProvider } from './lib/state';
import { fireTracker, setFirebaseNode } from './lib/firestarter';
import { getConfigForSlug } from './lib/sanity';
import { getURLParam, trimSlashes } from './lib/utils';

import ETQuiz from './ETQuiz';
import Loading from './components/Loading';

import './index.scss';

import * as serviceWorker from './serviceWorker';

// REVIEW: This whole methodolgy of setting a window variable
window.evoketools_render_quiz = async element => {
  const path = trimSlashes(window.location.pathname);
  const altUrl = getURLParam('baseUrl');
  const { quizId, styles, client, themeVariant, background } = await getConfigForSlug(path);
  // console.log({ client });
  let baseURL = altUrl && altUrl.length > 0 ? altUrl : process.env.REACT_APP_BASE_URL; // eslint-disable-line
  const trackBot = new TrackBot();
  const leaf = `${document.title}_${quizId}`;
  setFirebaseNode(leaf);
  const engageTracker = fireTracker(leaf);
  trackBot.addTracker(engageTracker);

  if (!quizId || !baseURL) {
    return;
  }
  if (baseURL.indexOf('admin-ajax.php') === -1) {
    baseURL = `${baseURL.replace(/\/?$/, '/')}wp-admin/admin-ajax.php`;
  }

  const enableInactiveQuiz = () => {
    var target = document.querySelector('.intro .content-block-item.text div p');
    if (target) {
      var buttonContent = '<br><button type="button" class="evoketools-next-btn" data-evoketools-start-quiz="">Start testen</button>';
      target.innerHTML += buttonContent;
    } else {
      window.setTimeout(enableInactiveQuiz, 100);
    }
  };

  ReactDOM.render(
    <>
      {quizId ? (
        <StateInspector name="everything">
          <StateProvider>
            <ETQuiz tracker={trackBot} quizId={quizId.toString()} url={baseURL} backgroundImage={background} themeConfig={themeVariant} client={client} styleConfig={styles} />
          </StateProvider>
        </StateInspector>
      ) : (
        <Loading label="Henter quiz!" />
      )}
    </>,

    element,
    //REVIEW: ⬇ What is this?
    // It's bringing inactive quizes back to life for demo purposes
    () => {
      if (window.location.search.indexOf('aktiv') !== -1) {
        enableInactiveQuiz();
      }
    },
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
