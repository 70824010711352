import React, { useState } from 'react';
import { func, object } from 'prop-types';
import styled from 'styled-components';

import Question from './Question';
import Answers from './Answers';
import Timer from './Timer';
import FullscreenVideo from './components/FullscreenVideo';
import { useStateValue } from './lib/state';
// import { urlFor } from './lib/sanity';
import { variables } from './styles/global/variables';
// import { hurtigruten } from './styles/themeStyles';
// import { themeStyles } from './themes/themeStyles';
// console.log({ themeStyles });
// TODO: Here's another bit of style

const talkMorestyles = `
    background: url("https://talkmore.no/-/media/images/900x500/900x500_baksetet.ashx?h=500&w=900&la=en&hash=5D7BC86E317841C70876F83ABE829B0246207D29")
    center center no-repeat;
    background-size: cover;
`;
const hurtigruteStyles = `
    background: url("https://www.hurtigruten.no/globalassets/photos/destinations/nwp/2500x1250_ms-fram-i-ilulissat-gronland-hgr-124938--foto_stian__klo.jpg?width=2500&height=1500&transform=DownFill")
    center center no-repeat;
    background-size: cover;
`;

const QuestionPageStyle = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* ${talkMorestyles} */
  ${hurtigruteStyles}
  &.full {
    > div:first-child {
      flex-grow: 1;
    }
  }
  &.centered {
    justify-content: center;
    align-items: center;
    > div:first-child {
      max-width: 450px;
      background: white;
      padding: 2rem;

      border-radius: 2rem;
      // Hurtigruten
      border-radius: 0;
    }
  }
  &.right {
    display: flex;
    flex-direction: row;
    > div {
      flex-grow: 1;
      display: grid;
      grid-template-columns: 2fr 1fr;

      > div {
      }
    }
    @media (max-width: ${p => p.v.small}px) {
      /* flex-direction: row; */
      flex-grow: 1;
      > div {
        /* border: 3px solid red; */
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

const QuestionJSXStyle = styled.div`
  /* display: flex;
  flex-direction: column;
  border: 3px solid red;
  &.right {
    flex-grow: 1;
    border: 3px solid black;
    display: grid;
    grid-template-columns: 2fr 1fr;
  } */
`;

const Quiz = ({ quiz, changeState, status }) => {
  const [{ questionsLayout }] = useStateValue();
  const [showQuestionVideo, setShowQuestionVideo] = useState(true);
  const [answerSelected, setAnswerSelected] = useState(false);
  const [answer] = useState(null);
  // TODO: I want the bgImage imported so I can use it in my CSS

  function onFinishVideo() {
    setShowQuestionVideo(false);
  }
  // REVIEW: ⬇ Apparently not in use. Remove.
  // function onQuestionChange() {
  //   setAnswer(null);
  //   setAnswerSelected(false);
  //   setShowQuestionVideo(true);
  // }
  function onTimeOver() {
    if (!answerSelected) {
      setAnswerSelected(true);
      changeState('answer', 'timeout');
    }
  }
  function selectAnswer(answer) {
    const { show_next_button: showNextButton } = quiz;
    if (!showNextButton) {
      setAnswerSelected(true);
      changeState('answer', answer);
    }
  }
  function nextButton() {
    if (!answer) {
      return;
    }
    setAnswerSelected(true);
    changeState('answer', answer);
  }

  // Everything from the class component's render function below this line
  const { currentPage, showQuestionVideo: showQuestionVideoProp, currentQuestion: currentQuestionProp } = status;
  const { questions, time_per_question: timePerQuestionProp, show_next_button: showNextButtonProp } = quiz;
  let timePerQuestion;
  if (currentPage !== 'question') {
    return null;
  }
  if (parseInt(timePerQuestionProp, 10) > 0) {
    timePerQuestion = parseInt(timePerQuestionProp, 10);
  }

  const currentQuestion = questions[currentQuestionProp];
  const hasFullscreenVideo = currentQuestion.question[0].acf_fc_layout === 'video_fullscreen';
  let nextVideoUrl = null;
  let videoUrlQuestionIndex;

  // nextVideoUrl should be:
  // - current question's url if the current question's video hasn't been
  // played yet
  // - next question's url if the current question's video has been played
  if (questions.length > currentQuestionProp) {
    videoUrlQuestionIndex = hasFullscreenVideo && showQuestionVideoProp ? currentQuestionProp : currentQuestionProp + 1;

    if (questions[videoUrlQuestionIndex]) {
      nextVideoUrl = questions[videoUrlQuestionIndex].question[0].video_url;
    }
  }

  const questionJSX = (
    <QuestionJSXStyle className={`et-question-container ${questionsLayout}`}>
      <Question question={currentQuestion.question} />
      {timePerQuestion ? <Timer question={currentQuestion.question} totalTime={timePerQuestion} onTimeOver={onTimeOver} /> : null}
      <Answers quiz={quiz} status={status} answers={currentQuestion.answers} selectAnswer={selectAnswer} />
      {showNextButtonProp ? (
        <div className="et-question-next-button">
          <button type="button" onClick={nextButton}>
            Neste
          </button>
        </div>
      ) : null}
    </QuestionJSXStyle>
  );

  //  <CSSTransitionGroup transitionName="instant-feedback-transition" transitionEnterTimeout={1} transitionLeaveTimeout={300}>
  //    {feedback ? (
  //      <InstantFeedback
  //        feedback={feedback}
  //        key="instant-feedback"
  //        showNextButton={quiz.show_instant_feedback_next_button}
  //        nextButtonText={quiz.instant_feedback_next_button_text}
  //        clickNextButton={() => saveAndProceed(currentAnswer)}
  //      />
  //    ) : null}
  //  </CSSTransitionGroup>;

  return (
    <QuestionPageStyle v={variables.mediaqueries} className={`et-question-page ${questionsLayout}`}>
      {hasFullscreenVideo && showQuestionVideo ? null : questionJSX}

      <FullscreenVideo nextVideoIndex={videoUrlQuestionIndex} nextVideoUrl={nextVideoUrl} onFinishVideo={onFinishVideo} />
    </QuestionPageStyle>
  );
};

Quiz.propTypes = {
  changeState: func.isRequired,
  quiz: object.isRequired,
  status: object.isRequired,
};

export default Quiz;
