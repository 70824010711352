import React from 'react';
import { object, array, oneOfType } from 'prop-types';
import ContentBlock from './ContentBlock';
import styled from 'styled-components';
import { variables } from './styles/global/variables';
import { useStateValue } from './lib/state';

const QuestionStyle = styled.div`
  /* background: rgba(3, 255, 255, 0.3); */
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    text-align: center;
    font-size: 2rem;
  }

  /* @keyframes test {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  } */

  img {
    max-width: 100%;
  }
  flex-basis: 250px;
  background: rgba(255, 255, 255, 0.9);

  @media (max-width: ${p => p.v.small}px) {
    padding: 1rem;
    flex-basis: 50vw;
    div {
      font-size: 1.5rem;
    }
  }
  @media (max-width: ${p => p.v.tiny}px) {
    div {
      font-size: 1.25rem;
    }
  }
  &.right {
    /* border: 3px solid red; */
    /* border: 13px solid blue; */
    /* flex-grow: 1;
    border: 3px solid red; */

    /* animation: test 1s ease-out 0s 1; */
    padding: 3rem;
    @media (max-width: ${p => p.v.tiny}px) {
      padding: 0;
      /* border: 3px solid red; */
    }
  }
`;

const Question = ({ question }) => {
  const [{ questionsLayout }] = useStateValue();
  console.log({ questionsLayout });
  return (
    <QuestionStyle v={variables.mediaqueries} className={`et-question ${questionsLayout}`}>
      <ContentBlock item={question} type="question" />
    </QuestionStyle>
  );
};

Question.propTypes = {
  question: oneOfType([object, array]).isRequired,
};

export default Question;
