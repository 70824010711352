/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { dangerouslyGetMarkup } from './lib/utils';
import styled from 'styled-components';
import Button from './atoms/Button';
import { variables } from './styles/global/variables';

const InstantFeedbackStyles = styled.div`
  /* background: blue; */

  --correct: green;
  --incorrect: red;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  text-align: center;
  font-size: 36px;
  color: white;
  background: var(--correct);
  > div {
    width: 100%;
    padding: 1rem;
    /* display: block; */
    /* border: 3px solid yellow; */
    @media (max-width: ${p => p.v.small}px) {
      /* border: 3px solid yellow; */
      font-size: 1rem;
    }
  }

  &.feedback-incorrect {
    background: var(--incorrect);
  }

  .instant-feedback-inner {
    /* display: table-cell; */
    /* vertical-align: middle; */
    /* max-width: 600px; */
    /* padding: 2vw; */
  }

  .instant-feedback-transition-enter {
    opacity: 0.01;
  }

  .instant-feedback-transition-enter.instant-feedback-transition-enter-active {
    opacity: 1;
    transition: opacity 0.2 ease-in;
  }

  .instant-feedback-transition-exit {
    opacity: 1;
  }

  .instant-feedback-transition-exit.instant-feedback-transition-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

  button {
    /* border: 1px solid white; */
    background: none !important;
    border-radius: 0;
    &:hover {
      background: rgba(255, 255, 255, 0.3) !important;
    }
    border: 1px solid white !important;
  }
`;

class InstantFeedback extends Component {
  render() {
    const { feedback, showNextButton, clickNextButton, nextButtonText } = this.props;
    if (!feedback) {
      document.querySelector('body').classList.remove('et-no-overflow-feedback');
      return null;
    }
    document.querySelector('body').classList.add('et-no-overflow-feedback');
    const cssClass = feedback.correct ? 'correct' : 'incorrect';
    return (
      <InstantFeedbackStyles v={variables.mediaqueries} className={`instant-feedback feedback-${cssClass} ${feedback.cssClass}`}>
        <div className="instant-feedback-inner">
          <div className="instant-feedback-text" dangerouslySetInnerHTML={dangerouslyGetMarkup(feedback.message)} />
          {showNextButton ? (
            <div className="instant-feedback-next">
              <Button type="button" buttonText={nextButtonText || 'Neste'} onClick={clickNextButton}></Button>
            </div>
          ) : null}
        </div>
      </InstantFeedbackStyles>
    );
  }
}
InstantFeedback.propTypes = {
  feedback: PropTypes.object.isRequired,
  showNextButton: PropTypes.bool.isRequired,
  clickNextButton: PropTypes.func.isRequired,
  nextButtonText: PropTypes.string.isRequired,
};

export default InstantFeedback;
