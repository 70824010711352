import { Tracker } from '@evokedset/orwell';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const devMode = process.env.NODE_ENV === 'development';

const firebaseConfig = {
  apiKey: 'AIzaSyDGk3fbv3sns1lPVWH_Gf7iuTV4QdWpgSM',
  authDomain: 'engage-2.firebaseapp.com',
  databaseURL: 'https://engage-2-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'engage-2',
  storageBucket: 'engage-2.appspot.com',
  messagingSenderId: '344643938650',
  appId: '1:344643938650:web:be96e55ce7d06f0c346b63',
};
firebase.initializeApp(firebaseConfig);

let id = 'BASE_ID';

export function setFirebaseNode(leaf) {
  id = firebase.database().ref().child(leaf).push().key;
}

const updateFirebase = ({ type = 'pageView', eventType = undefined, value = undefined, params = undefined }) => {
  let leaf = 'NODE_KEY_PLACEHOLDER';
  if (params && params.nodeKey) {
    leaf = params.nodeKey;
  }
  // const getid = leaf => firebase.database().ref().child(leaf).push().key;
  // const id = getid(leaf);
  // const id = firebase.database().ref().child(leaf).push().key;

  const variables = {
    pageView: {
      child: 'pageViews',
      label: 'pageName',
    },
    event: {
      child: 'events',
      label: 'eventValue',
    },
    init: {
      child: 'init',
      label: 'initials',
    },
  };
  const output = type === 'event' ? { eventType, value } : value;
  const fireKey = firebase.database().ref().child(variables[type].child).push().key;

  const update = {};
  if (type === 'pageView') {
    update[`${leaf}/${id}/${type}/${fireKey}`] = {
      [variables[type].label]: output,
      ...params,
    };
    update[`${leaf}/${id}/mostRecent`] = {
      node: fireKey,
      type,
      value,
      ...params,
    };
    update[`${leaf}/${id}/progressLevel`] = params.level;
  } else if (type === 'event') {
    update[`${leaf}/${id}/${type}/${fireKey}`] = {
      eventType,
      value,
      ...params,
    };
    update[`${leaf}/${id}/mostRecent`] = {
      node: fireKey,
      type,
      eventType,
      value,
      ...params,
    };
  } else if (type === 'init') {
    update[`${leaf}/${id}/initials`] = value;
  }

  return firebase.database().ref().update(update);
};

export const fireTracker = nodeKey =>
  new Tracker({
    vendor: 'evokedset',
    nodeKey,
    init: () =>
      updateFirebase({
        type: 'init',
        value: {
          sessionStart: new Date().getTime(),
          agent: window.navigator.userAgent || 'Undetermined User Agent',
          referrer: document.referrer || 'No referrer',
          vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
          vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
          devMode,
        },
        params: { nodeKey },
      }),
    event: ({ eventType, value, params = {} }, ...args) => updateFirebase({ type: 'event', eventType, value, params: { nodeKey, ...params } }, ...args),
    pageView: ({ pageName, params = {} }, ...args) => updateFirebase({ type: 'pageView', value: pageName, params: { nodeKey, ...params } }, ...args),
  });
