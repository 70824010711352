import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FullscreenVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessage: false,
    };
  }

  componentDidMount() {
    const { onFinishVideo, nextVideoUrl } = this.props;
    /* eslint-disable global-require */
    this.Clappr = require('clappr');
    /* eslint-enable global-require */

    // Create a new DOM node directly attached to the `body` that will act as
    // our video player
    this.containerEl = document.createElement('div');
    this.playerEl = document.createElement('div');
    this.containerEl.appendChild(this.playerEl);
    this.containerEl.className = 'et-fullscreen-video-player hidden';
    this.button = document.createElement('button');
    this.button.innerHTML = 'Hopp over video';
    this.button.addEventListener(
      'click',
      () => {
        this.stop(true);
        onFinishVideo();
      },
      true
    );
    this.containerEl.appendChild(this.button);
    document.body.appendChild(this.containerEl);
    this.load();

    if (nextVideoUrl) {
      this.play();
    }

    // Expose a global function to play the video on the next button
    window.engageVideoPlay = () => {
      this.play();
    };
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }

  destroyPlayer() {
    if (this.player && this.player.destroy) {
      this.player.destroy();
    }
  }

  trackEvent(eventName) {
    const { nextVideoIndex } = this.props;
    const category = `evoketools-quiz - ${document.title}`;
    const action = 'Besøkt';
    const label = `Question ${nextVideoIndex} : ${eventName}`;
    if (window.s) {
      window.s.clearVars();
      window.s.pageName = `${category}:${label}`;
      // eslint-disable-next-line no-void
      void window.s.t();
    }
    if (window.ga) {
      window.ga('send', 'event', category, label, action);
    }
    if (window.fbq) {
      window.fbq('track', 'ViewContent', { value: label });
    }
    if (window.utag) {
      window.utag.view({
        page_name: `${category}:${label}`,
        page_hierarchy: `${category}:${label}`,
      });
    }
  }

  stop(isSkipped) {
    if (!this.player) {
      return;
    }

    let timeString = '';
    if (isSkipped) {
      const skipTime = 5 * Math.floor(this.player.getCurrentTime() / 5);
      timeString = `between ${skipTime} - ${skipTime + 5} sec`;
    }
    this.trackEvent(
      isSkipped ? `skipped video ${timeString}` : 'completed video'
    );
    this.player.stop();
    this.containerEl.classList.add('hidden');
  }

  change(source) {
    if (!this.player) {
      return;
    }
    if (!source) {
      this.player.stop();
      return;
    }
    this.player.load(source, '', false);
    // this.player.play();
  }

  play() {
    const { nextVideoUrl } = this.props;
    if (nextVideoUrl) {
      this.containerEl.classList.remove('hidden');
      this.change(nextVideoUrl);
      const playback = this.player.core.getCurrentPlayback();
      if (playback.name === 'flashls') {
        this.containerEl.classList.add('hidden');
        this.setState({ showErrorMessage: true });
        // alert('Your browser is not supported. Please use another up-to-date browser like Google Chrome or Mozilla Firefox.');
        this.trackEvent('incompatible browser warning');
      } else {
        if (playback.isReady) {
          this.player.play();
        } else {
          playback.once(this.Clappr.Events.PLAYBACK_READY, () => {
            window.setTimeout(() => {
              this.player.play();
            }, 500);
          });
        }
        // this.player.play();
        this.trackEvent('started video');
      }
    }
  }

  load() {
    const { onFinishVideo } = this.props;
    this.player = new this.Clappr.Player({
      parent: this.playerEl,
      // source,
      width: '100vw',
      height: '100vh',
      autoPlay: false,
      chromeless: !this.Clappr.Browser.isMobile,
      allowUserInteraction: false,
      loop: false,
      controls: false,
      autoSeekFromUrl: false,
      plugins: [this.Clappr.HLS],
      playback: {
        controls: false,
      },
      hlsjsConfig: {
        enableWorker: true,
      },
      events: {
        onEnded: () => {
          this.trackEvent('finished video');
          this.player.load('');
          onFinishVideo();
          this.containerEl.classList.add('hidden');
        },
        onError: (args) => {
          console.warn('Could not play the video', ...args);
          onFinishVideo();
          this.containerEl.classList.add('hidden');
        },
      },
    });
  }

  render() {
    const { showErrorMessage } = this.state;
    if (showErrorMessage) {
      return (
        <div className='fullscreen-warning'>
          <div className='browsermessage'>
            <h1>Ooops!</h1>
            <p>
              Vi ser at du bruker en utdatert nettleser. For å få en optimal
              opplevelse av vår video-quiz anbefaler vi at du laster ned en ny
              versjon
              <a href='https://browsehappy.com/'>her</a>.
            </p>
          </div>
        </div>
      );
    }

    return <div />;
  }
}

FullscreenVideo.defaultProps = {
  nextVideoUrl: null,
};

FullscreenVideo.propTypes = {
  onFinishVideo: PropTypes.func.isRequired,
  nextVideoIndex: PropTypes.number.isRequired,
  nextVideoUrl: PropTypes.string,
};

export default FullscreenVideo;
