import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { dangerouslyGetMarkup } from './lib/utils';

class MemoryGame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tiles: [],
      currentState: [],
    };
    this.extraTileCss = {};

    this.allowClicks = true;
  }

  componentDidMount = () => {
    this.setupGame();

    window.addEventListener(
      'resize',
      () => {
        this.updateHeight();
      },
      false
    );
    this.updateHeight();
  };

  setupGame = () => {
    const { quiz } = this.props;
    const { tiles } = this.state;

    if (!quiz) {
      window.setTimeout(() => {
        this.setupGame();
      }, 200);
      return;
    }

    if (!quiz.quiz_type) {
      window.setTimeout(() => {
        this.setupGame();
      }, 200);
      return;
    }

    if (quiz.quiz_type !== 'memory-game') {
      return;
    }

    if (tiles.length === 0) {
      this.generateTiles();
    }
  };

  preloadImage = (url) => {
    const img = new Image();
    img.src = url;
  };

  generateTiles = () => {
    const {
      quiz: { memory_game_images: memoryGameImagesProp },
    } = this.props;
    // Take all unique tiles, double them, shuffle them and save to
    // this.state.tiles
    this.tiles = memoryGameImagesProp.map((image) => image.memory_game_image);

    this.tiles.forEach((tile) => {
      if (tile && tile.sizes) {
        this.preloadImage(tile.sizes.thumbnail);
      }
    });

    this.tiles = this.tiles.concat(this.tiles);

    // Fill this.state.currentState with 0s for the number of tiles.
    this.setState({
      tiles: _.shuffle(this.tiles),
      currentState: _.times(this.tiles.length, _.constant(0)),
    });
  };

  startGame = () => {
    const { currentState } = this.state;
    const {
      quiz: { time_for_quiz: timeForQuizProp },
    } = this.props;
    // Probably should count number of clicks at this point of time.
    if (currentState.indexOf(1) !== -1 || currentState.indexOf(2) !== -1) {
      return;
    }
    this.updateHeight();
    this.startTime = new Date();
    this.updateTimer = window.setInterval(() => {
      const elapsed = new Date() - this.startTime;
      this.setState({ elapsed });
      if (timeForQuizProp < elapsed / 1000) {
        this.finishGame();
      }
    }, 30);
  };

  finishGame = () => {
    const { changeState } = this.props;
    const { elapsed } = this.state;
    if (this.updateTimer) {
      window.clearInterval(this.updateTimer);
      this.updateTimer = null;
    }
    this.allowClicks = false;
    changeState('finishGame', elapsed);
  };

  handleClick = (event) => {
    if (event.target.hasAttribute('href')) {
      return;
    }
    event.preventDefault();
    if (event.target.hasAttribute('data-evoketools-start-quiz')) {
      this.restartGame();
    }
    if (event.target.hasAttribute('data-evoketools-next-page')) {
      this.restartGame();
    }
  };

  restartGame = () => {
    if (this.updateTimer) {
      window.clearInterval(this.updateTimer);
      this.updateTimer = null;
    }
    this.startTime = null;
    this.generateTiles();
    this.setState({ elapsed: 0 });
    this.updateHeight();
    this.allowClicks = true;
  };

  // 0 => closed. 1 => open & matched. 2 => open & not matched.
  clickTile = (index) => {
    const { currentState, tiles } = this.state;
    let newCurrentState;
    newCurrentState = currentState;
    if (!this.updateTimer) {
      this.startGame();
    }
    if (!this.allowClicks) {
      return;
    }

    this.allowClicks = false;

    // already open, do nothing
    if (currentState[index] === 1 || currentState[index] === 2) {
      this.allowClicks = true;
      return;
    }

    // none open, open one.
    if (currentState.indexOf(2) === -1) {
      newCurrentState[index] = 2;
      this.allowClicks = true;
      this.setState({ currentState: newCurrentState });
      return;
    }

    const counts = _.reduce(
      currentState,
      (count, n) => (n === 2 ? 1 : 0) + count,
      0
    );

    if (counts === 2) {
      if (this.closeTilesTimeout) {
        window.clearTimeout(this.closeTilesTimeout);
      }
      newCurrentState = currentState.map((state) => (state === 2 ? 0 : state));
      newCurrentState[index] = 2;
      this.allowClicks = true;
      this.setState({ currentState: newCurrentState });
      return;
    }

    const alreadyOpenIndex = currentState.indexOf(2);
    newCurrentState[index] = 2;
    this.setState({ currentState: newCurrentState });
    // Check the state of the tiles.
    window.setTimeout(() => {
      if (tiles[index].id === tiles[alreadyOpenIndex].id) {
        newCurrentState[index] = 1;
        newCurrentState[alreadyOpenIndex] = 1;

        if (
          newCurrentState.indexOf(0) === -1 &&
          newCurrentState.indexOf(2) === -1
        ) {
          this.finishGame();
        }
      } else {
        this.closeTilesTimeout = window.setTimeout(() => {
          newCurrentState = currentState.map((state) =>
            state === 2 ? 0 : state
          );
          this.setState({ currentState: newCurrentState });
        }, 5000);
      }
      this.allowClicks = true;
      this.setState({ currentState: newCurrentState });
    }, 200);
  };

  getDisplayTime = (time) => {
    if (_.isNaN(time)) {
      return '00:00.00';
    }
    const minutes = parseInt(time / (60 * 1000), 10) % 60;
    const seconds = parseInt(time / 1000, 10) % 60;
    const hundredths = Math.floor(parseInt(time % 1000, 10) / 10);

    return `${(minutes < 10 ? '0' : '') + minutes}:${
      (seconds < 10 ? '0' : '') + seconds
    }.${(hundredths < 10 ? '0' : '') + hundredths}`;
  };

  updateHeight = () => {
    const { quiz } = this.props;
    if (
      !quiz.memory_game_full_quiz_unopened_image ||
      window.innerWidth < 800 ||
      window.innerHeight < 600
    ) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({
        memoryGameContainerStyle: { height: 'initial' },
        extraTileCss: {},
      });
      return;
    }

    if (quiz.memory_game_narrow_layout) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({
        memoryGameContainerStyle: { height: 'initial' },
        extraTileCss: {},
      });
      return;
    }

    window.requestAnimationFrame(() => {
      const contentHeight = this.getPlaygroundHeight();
      // eslint-disable-next-line no-restricted-properties
      const tilesPerRow = Math.ceil(Math.pow(this.tiles.length, 0.5));
      const rows = Math.ceil(this.tiles.length / tilesPerRow);
      const height = Math.floor(contentHeight / rows);
      this.setState({
        extraTileCss: { height: `${height}px` },
      });
    });
  };

  getPlaygroundHeight = () => {
    const elHeader = document.getElementsByClassName('et-header');
    const elTitle = document.getElementsByClassName('memorygame-title-area');
    const elTimer = document.getElementsByClassName('memorygame-timer');
    const els = [elHeader, elTitle, elTimer];
    let otherHeight = _.reduce(
      els,
      (sum, el) => sum + (el[0] ? el[0].clientHeight : 0),
      0
    );
    otherHeight += 15; // some borders were not added.
    const contentHeight = window.innerHeight - otherHeight;
    return contentHeight;
  };

  renderTiles = () => {
    const { tiles, currentState, extraTileCss } = this.state;
    const { quiz } = this.props;
    const processedTiles = tiles.map((tile, index) => {
      let tileClass = 'open';
      let tileCss = {};
      if (currentState[index] === 0) {
        tileClass = 'closed';
        if (!quiz.memory_game_full_quiz_unopened_image) {
          tileCss = {
            background: `url(${quiz.memory_game_unopened_image.sizes.thumbnail}) center center / contain no-repeat`,
          };
        }
      }
      if (currentState[index] === 1) {
        tileClass = 'solved';
        tileCss = {
          background: `url(${tile.sizes.thumbnail}) center center / contain no-repeat`,
          opacity: 0.5,
        };
      }
      if (currentState[index] === 2) {
        tileCss = {
          background: `url(${tile.sizes.thumbnail}) center center / contain no-repeat`,
        };
      }
      if (extraTileCss) {
        tileCss = _.extend(tileCss, extraTileCss);
      }
      return (
        <div
          className={`${tileClass} memorygame-tile`}
          key={index} // eslint-disable-line
          onClick={(event) => {
            this.clickTile(index, tile, event);
          }}
        >
          <div className='memorygame-tile-content' style={tileCss} />
        </div>
      );
    });
    return processedTiles;
  };

  render() {
    const { status, quiz } = this.props;
    const { elapsed } = this.state;

    if (status.currentPage !== 'memory-game') {
      document.querySelector('body').classList.remove('et-no-overflow-mg');
      return null;
    }

    if (
      quiz.memory_game_full_quiz_unopened_image &&
      window.innerHeight > 700 &&
      window.innerWidth >= 1100
    ) {
      document.querySelector('body').classList.add('et-no-overflow-mg');
    } else {
      document.querySelector('body').classList.remove('et-no-overflow-mg');
    }

    const tiles = this.renderTiles();
    const displayTime = this.getDisplayTime(elapsed);

    let gameStyle = {};
    if (quiz.memory_game_full_quiz_unopened_image) {
      gameStyle = {
        background: `url(${quiz.memory_game_unopened_image.url})`,
        backgroundSize: 'cover',
      };
    }

    if (
      !quiz.memory_game_narrow_layout &&
      quiz.memory_game_full_quiz_unopened_image
    ) {
      // const gameHeight = this.getPlaygroundHeight();
      // gameStyle.maxHeight = gameHeight + 'px';
    }

    let percent = elapsed / (10 * quiz.time_for_quiz);
    if (percent > 100) {
      percent = 100;
    }
    let red = 0;
    let blue = 0;
    let green = 255;
    // red = Math.round(Math.floor(percent) * 2.56);
    // green = Math.round(Math.floor(100 - percent) * 2.56);
    let textStyle = {
      color: percent < 82 ? 'black' : 'white',
    };
    if (percent > 60 && percent <= 70) {
      red = Math.round(Math.floor(10 * (percent - 60)) * 2.56);
      green = 255;
    }
    if (percent > 70 && percent <= 80) {
      red = 255;
      green = 255;
    }
    if (percent > 80 && percent <= 90) {
      red = 255;
      green = Math.round(Math.floor(10 * (90 - percent)) * 2.56);
    }
    if (percent > 90) {
      red = 255;
      green = 0;
    }
    if (percent >= 100) {
      red = 0;
      green = 0;
      blue = 0;
      textStyle = {
        color: 'white',
      };
    }
    const meterStyle = {
      width: `${percent}%`,
      backgroundColor: `rgb(${red},${green},${blue})`,
    };

    // eslint-disable-next-line no-restricted-properties
    const tilesPerRow = `tiles-per-row-${Math.ceil(
      Math.pow(this.tiles.length, 0.5)
    )}`;
    let extraClasses = '';
    if (quiz.memory_game_narrow_layout) {
      extraClasses = 'narrow-layout';
    }

    return (
      <div className='et-memory-game'>
        <div className='memorygame-title-area'>
          <div className='memorygame-title' onClick={this.handleClick}>
            {quiz.memory_game_above_game_text ? (
              <div
                dangerouslySetInnerHTML={dangerouslyGetMarkup(
                  quiz.memory_game_above_game_text
                )}
              />
            ) : (
              <div>
                <h2>
                  Finn matchende bilder innen {quiz.time_for_quiz} sekunder
                </h2>
                <div className='memorygame-restart'>
                  <button type='button' data-evoketools-start-quiz='1'>
                    Restart
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='memorygame-timer timer'>
          <div className='timer-progress'>
            <div className='timer-text' style={textStyle}>
              {displayTime}
            </div>
            <div
              className={`${percent > 80 ? 'danger' : ''} timer-meter`}
              style={meterStyle}
            />
          </div>
        </div>
        <div
          className={`${tilesPerRow} ${extraClasses} memorygame-tiles`}
          style={gameStyle}
        >
          {tiles}
        </div>
      </div>
    );
  }
}

MemoryGame.propTypes = {
  quiz: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  changeState: PropTypes.func.isRequired,
};

export default MemoryGame;
